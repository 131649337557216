*:focus {
    outline: none;
}

.footerSection{
    background-color: #FAFFFA;
    min-height: 200px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.footerTopContainer{
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    
}

.footerTopLeftContainer{
    /* background-color: purple; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.footerContainerListContainer{
    text-decoration: none;
    list-style-type: none;
    /* background-color: wheat; */
    padding:0px;
}

.footerContainerListContainer li{
    /* background-color: aqua; */
    padding: 0px;
    margin-top: 10px;
} 

.footerContainerListContainerLink:hover{
    /* background-color: red; */
    color:orange;
}

.contactMetag{
    /* background-color: blue; */
    text-align: center;
}

.individualFormLineInput{
    /* background-color: aquamarine; */
    display: flex;
    width:70%;
    height: 42px;

}
.individualFormLineInput p{
    /* background-color: aquamarine; */
    display: flex;
    width:30%;
}
.individualFormLineInput input{
    background-color: transparent;
    display: flex;
    width:70%;
    border:none;
}

.individualFormLineInputButton{
    text-align: right;
    width: 90%;
    margin-top: 35px;
}

.individualFormLineInputButton button{
    width:104px;
    height: 42px;
    border-radius: 15px;
    background-color: transparent;
    transition: all .3s linear;
    cursor: pointer;
}

.individualFormLineInputButton button:hover{
    background-color: #FAFFFA;
    color: #FF9601;
    transition: all .3s linear;
}

@media  screen and (max-width: 800px) {
    .footerTopContainer{
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        text-align: center;
    }

    .footerTopRightContainer form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media  screen and (max-width: 600px) {
    .individualFormLineInput{
        /* background-color: pink; */
        display: flex;
        flex-direction: column;
        width:90%;
        height: auto;
    }

    .individualFormLineInput p{
        width:100%;
    }

    .individualFormLineInput input{
        width:100%;
    }
}