/* General page fonts */
@import url('https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
/* End of general page fonts */

/* Landing page fonts */
@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Text&display=swap');
/* End of landing page fonts */

/* Font-Weight */
.fontBlack{
    font-weight:900;
}

.fontExtraBold{
    font-weight:800;
}

.fontBold{
    font-weight:700;
}

.fontSemiBold{
    font-weight:600;
}

.fontMedium{
    font-weight:500;
}

.fontRegular{
    font-weight:400;
}

.fontLight{
    font-weight:300;
}

.fontExtraLight{
    font-weight:200;
}

.fontThin{
    font-weight:100;
}

/* End of Font-Weight */

/* Default fonts */
.landingPageFont{
    font-size: 109.6564171px !important;
    font-family: 'Lato', sans-serif;
}

.h1{
    font-size: 177.4240829px !important;
    font-family: 'Lato', sans-serif;
}

.h2{
    font-size: 109.6564171px !important;
    font-family: 'Lato', sans-serif;
}

.h3{
    font-size: 67.77281651px !important;
    font-family: 'Lato', sans-serif;
}

.h4{
    font-size: 41.886784px !important;
    font-family: 'Lato', sans-serif;
}


.h5{
    font-size: 25.888px !important;
    font-family: 'Lato', sans-serif;
}

.subP{
    font-size: 16px !important;
    font-family: 'Lato', sans-serif;
}

.subPRegular{
    font-size: 16px !important;
    font-family: 'Lato', sans-serif;
}

/* End of default */

/* tablet size fonts */

@media screen and (max-width:966px){
    .landingPageFont{
    font-size: 67.77281651px !important;
    font-family: 'Lato', sans-serif;
    }

    .h1{
        font-size: 109.6564171px !important;
        font-family: 'Lato', sans-serif;
    }

    .h2{
        font-size: 67.77281651px !important;
        font-family: 'Lato', sans-serif;
    }

    .h3{
        font-size: 41.886784px !important;
        font-family: 'Lato', sans-serif;
    }

    .h4{
        font-size: 25.888 !important;
        font-family: 'Lato', sans-serif;
    }


    .h5{
        font-size: 20px !important;
        font-family: 'Overpass', sans-serif;
    }

    .subP{
        font-size: 16px !important;
        font-family: 'Lato', sans-serif;
    }

    .subPRegular{
        font-size: 16px !important;
        font-family: 'Lato', sans-serif;
    }
}

/* End of tablet size */

/* Big Phone size fonts */
@media screen and (max-width:580px){
    .landingPageFont{
    font-size: 41.886784px !important;
    font-family: 'Lato', sans-serif;
    }

    .h1{
        font-size: 67.77281651px !important;
        font-family: 'Lato', sans-serif;
    }

    .h2{
        font-size: 41.886784px !important;
        font-family: 'Lato', sans-serif;
    }

    .h3{
        font-size: 25.888px !important;
        font-family: 'Lato', sans-serif;
    }

    .h4{
        font-size: 16px !important;
        font-family: 'Lato', sans-serif;
    }


    .h5{
        font-size: 16px !important;
        font-family: 'Lato', sans-serif;
    }

    .subP{
        font-size: 16px !important;
        font-family: 'Lato', sans-serif;
    }

    .subPRegular{
        font-size: 16px !important;
        font-family: 'Lato', sans-serif;
    }
}
/* End of big phone size fonts */

/* Small Phone size fonts */
@media screen and (max-width:380px){
    .landingPageFont{
    font-size: 25.888px !important;
    font-family: 'Lato', sans-serif;
    }

    .h1{
        font-size: 41.886784px !important;
        font-family: 'Overpass', sans-serif;
    }

    .h2{
        font-size: 25.888px !important;
        font-family: 'Lato', sans-serif;
    }

    .h3{
        font-size: 16px !important;
        font-family: 'Lato', sans-serif;
    }

    .h4{
        font-size: 16px !important;
        font-family: 'Lato', sans-serif;
    }


    .h5{
        font-size: 16px !important;
        font-family: 'Lato', sans-serif;
    }

    .subP{
        font-size: 16px !important;
        font-family: 'Lato', sans-serif;;
    }

    .subPRegular{
        font-size: 16px !important;
        font-family: 'Lato', sans-serif;
    }
}
/* End of small phone size fonts */