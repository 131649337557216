.StepsConsultingMainContainer{
    /* background-color: yellow; */
    min-height: 500px;
    margin-bottom:40px;
}

.StepsConsultingHeadingContainer{
    /* background-color: red; */
}

.StepsConsultingStepsContainer{
    /* background-color: aqua; */
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.StepsConsultingStepsListContainer{
    /* background-color: bisque; */
    width:60%;
    max-height: 300px;
    overflow: scroll;
     overflow-x: hidden;
}

.StepsConsultingStepsListContainer ul{
    width:100%;
}

.StepsConsultingStepsListContainer ul li{
    text-decoration: none;
    list-style-type: none;
    margin-bottom:30px;
    /* background-color: bisque; */
}

.StepsConsultingStepsListContainer::-webkit-scrollbar {
    width: 5px;
}

.StepsConsultingStepsListContainer::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.StepsConsultingStepsListContainer::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #445346;
}


@media  screen and (max-width: 950px) {
    .StepsConsultingMainContainer{
        /* background-color: yellow; */
        min-height: 300px;
    }

    .StepsConsultingStepsListContainer{
        width:95%;
        /* background-color: blueviolet; */
    }
}

@media  screen and (max-width: 600px) {
    .heroSubHeadingContainer{
        min-height: 150px;
    }
    .textCopyWritingContainer{
        /* background-color: blueviolet; */
        min-height: 150px;
        display: flex;
        align-items: flex-end;
    }
    .textCopyWritingContent{
        /* background-color: pink; */
        max-width:80%;
    }
}