.heroSectionTemplateMainContainer{
    /* background-color: bisque; */
    min-height: 500px;
}

.heroHeadingContainer{
    /* background-color: blue; */
    text-align: center;
    margin-top: 30px;
}

.heroSubHeadingContainer{
    /* background-color: red; */
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.heroSubHeading{
    width:50%;
    /* background-color: blueviolet; */
}

.textCopyWritingContainer{
    /* background-color: blueviolet; */
    min-height: 300px;
    display: flex;
    align-items: flex-end;
}

.textCopyWritingContent{
    /* background-color: pink; */
    max-width:40%;
}


@media  screen and (max-width: 950px) {
    .heroSubHeading{
        width:80%;
        /* background-color: blueviolet; */
    }
    .textCopyWritingContent{
        /* background-color: pink; */
        max-width:60%;
    }
}

@media  screen and (max-width: 600px) {
    .heroSectionTemplateMainContainer{
        min-height: 400px;
    }
    .heroSubHeadingContainer{
        min-height: 150px;
    }
    .textCopyWritingContainer{
        /* background-color: blueviolet; */
        min-height: 150px;
        display: flex;
        align-items: flex-end;
    }
    .textCopyWritingContent{
        /* background-color: pink; */
        max-width:80%;
    }  
}