.homepageContainer{
    /* background-color: red; */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}

.arrow{
    /* background-color: orange; */
    position: fixed;
    right:4px;
    /* animation: 3s arrowAnimation linear forwards; */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    top:0px;
    height: 100%;
}

.arrowIconHolder{
    /* background-color: aquamarine; */
    display: flex;
    justify-content: center;
    align-items: center;
    width:90%;
    height: 100%;  
    flex-direction: column;
}

.arrowBody{
    /* background-color: pink; */
    width:10%;
    height: 100%;
}



@keyframes arrowAnimation {
    0%{
        height: 0%;
    }
    100%{
        height: 100%;
    }
    
}

.aboutSection{
    background-color: bisque;
    min-height: 500px;
}

.servicesSection{
    background-color: pink;
    min-height: 500px;
}

.footer{
    background-color: rebeccapurple;
    min-height: 400px;
}