.caseStudyTemplateMainContainer{
    min-height: 500px;
    /* background-color: red; */
    padding-bottom:24px;
}

.caseStudyTemplateIndividualTopContainer{
    /* background-color: yellow; */
    display:flex;
    justify-content: space-between;
    min-height: 600px;
    padding-bottom: 20px;
}

.caseStudyTemplateIndividualTopLeftContainer{
    /* background-color: purple; */
    width:50%;
    min-height: 482px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.caseStudyTemplateIndividualTopLeftContainer img{
    width:100%;
    height: 100%;
    object-fit: contain;
}

.caseStudyTemplateIndividualTopRightContainer{
    /* background-color: pink; */
    width:50%;
    min-height: 482px;
    display: flex;
    flex-direction: column;
}

.caseStudyTemplateIndividualTopMoodContainer{
    /* background-color: orange; */
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.caseStudyTemplateIndividualTopMoodContainer img{
    height: 100%;
    width:100%;
    object-fit: contain;
}

.caseStudyTemplateIndividualTopExplContainer{
    /* background-color: brown; */
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.caseStudyTemplateIndividualTopExpl{
    /* background-color: red; */
    width:95%;
   
}
.caseStudyTemplateIndividualTopExplContainer p{
    /* background-color: yellow; */
    height: 90%;
    margin:0px;
}

.caseStudyTemplateIndividualTopMoodContainer{
    /* background-color: red; */
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.caseStudyTemplateIndividualTopMood{
    /* background-color: yellowgreen; */
    width:95%;
    height: 90%;
}

.caseStudyTemplateIndividualMiddleContainer{
    width:100%;
    min-height: 200px;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:20px;
    padding-bottom:20px;
}

.caseStudyTemplateIndividualMiddleContainer img{
    height:100%;
    width: 100%;
    object-fit: contain;
}
@media  screen and (max-width: 950px) {
    .caseStudyTemplateIndividualTopContainer{
        flex-direction: column;
    }
    
    .caseStudyTemplateIndividualTopLeftContainer{
        width:100%;
    }

    .caseStudyTemplateIndividualTopRightContainer{
        width:100%;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        min-height: 250px;
    }

    .caseStudyTemplateIndividualTopExplContainer p{
        /* background-color: orange; */
        padding-top:20px;
        padding-bottom:20px;
    }

   
}

@media  screen and (max-width: 600px) {
    .heroSectionTemplateMainContainer{
        min-height: 400px;
    }
    .heroSubHeadingContainer{
        min-height: 150px;
    }
    .textCopyWritingContainer{
        /* background-color: blueviolet; */
        min-height: 150px;
        display: flex;
        align-items: flex-end;
    }
    .textCopyWritingContent{
        /* background-color: pink; */
        max-width:80%;
    } 
}