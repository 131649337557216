.primaryClrBGLight{
    background-color: #FAFFFA;
}

.primaryClrBGDark{
    background-color: #445346;
}

.primaryClr{
    background-color: #445346;
}

.secondaryClr{
    background-color: #FF9601;
}
.TertiaryClr {
    background-color: #B5C2B7;
}

.fontClrPrimary{
    color: #B5C2B7;
}

.fontClrWhite{
    color:#FAFFFA;
}

.fontClrBlack{
    color:#262927;
}

.fontSecondaryClr {
  color: #FF9601;
}

.fontPrimaryClr{
    color:#445346;
}