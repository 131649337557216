.transitionBackButtonTemplate{
    transition: all .5s linear;
    
}

.backButtonMainContainer{
    /* background-color: pink; */
    min-height: 100px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.linkContainer{
    /* background-color: blue; */
    width:80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.arrowContainer{
    /* background-color: blueviolet; */
    width:80%;
    display: flex;
    align-items: center;    
}

.arrowLessThan{
    font-size:24px;
}

.arrowBody{
    width: 90%;
    height: 3px;
    /* background-color: black; */
}


.wordBackContainer{
    /* background-color: purple; */
    width:20%;
}

@media screen and (max-width:400px){ 
     .arrowLessThan{
        font-size:16px;
    }
    .arrowBody{
        width: 90%;
        height: 2px;
    }
}