.ServiceSection{
    background-color: #FAFFFA;
    min-height: 600px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

}

.serviceTopContainer{
    /* background-color: yellow; */
    max-width:100%;
    margin:10px;
    padding-left:120px;
}

.serviceBottomContainer{
    /* background-color: aqua; */
    max-width:100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin:10px;
}

.serviceBottomContainerList{
    /* background-color: pink; */
    max-width:100%;
    list-style-type: none;
    margin-right: 60px;
}

.serviceBottomContainerList li{
    text-decoration: none;
    color:red;
    margin:30px;
}
.serviceBottomContainerListLink{
    text-decoration: "none";
    transition: all .3s linear; 
}

.serviceBottomContainerListLink:hover{
    color:orange;
    transition: all .3s linear;
}

@media  screen and (max-width: 950px) {
    .heroSection{
        background-color: #FAFFFA;
        min-height: 400px;
    }
    
    .serviceTopContainer{
        padding-left:90px;
        margin:10px;
    }

    .heroRightContainer{
        max-width:45%;
        margin:10px;  
    }
    .serviceBottomContainerList{
    /* background-color: pink; */
        max-width:100%;
        list-style-type: none;
        margin-right: 0px;
    }
}

@media  screen and (max-width: 600px) {
    .ServiceSection{
        min-height: 300px;
    }
    
    .serviceTopContainer{
        padding-left:0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .serviceBottomContainerList{
        margin-right: 0px;
    }
    .heroRightContainer{
        max-width:45%;
        margin:10px; 
    }
}