// terms and condition popup
.homeTermsNConditionContainer{
    min-height: 53px;
    width:80%;
    border-radius: 50px;
    position: fixed;
    top:6%;
    left:50%;
    transform: translate(-50%, -6%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left:60px;
    padding-right:60px;
    z-index:100;
    // background-color: red;
    
    .transitionTnC{
        transition: all 0.3s linear;
    }

    p{
        display: inline-block;
        padding:5px;
    }

    @media screen and (max-width:744px) {
        .p{
            padding:3px !important;  
        }
    }

    @media screen and (max-width:430px) {
        .p{
            padding:2px !important;
        }
    }

    @media screen and (max-width:320px) {
        .p{
            padding:1px !important;
        }
    }

    button{
        width:113px;
        height: auto;
        text-align: center;
        border: none;
        cursor:pointer;
        padding:5px;
        border-radius: 5px;
    }
}

.tNCAccepted{
    display: none;
}

@media screen and (max-width:744px) {
    .homeTermsNConditionContainer{
        padding-left:40px;
        padding-right:40px;  
    }
}

@media screen and (max-width:430px) {
    .homeTermsNConditionContainer{
        padding-left:20px;
        padding-right:20px;
    }
}

@media screen and (max-width:320px) {
    .homeTermsNConditionContainer{
        padding-left:16px;
        padding-right:16px;
    }
}