.aboutMeSection{
    background-color: #FAFFFA;
    min-height: 600px;
    display: flex;
    flex-direction: column;
}

.aboutMeTopContainer{
    /* background-color: yellow; */
    max-width:100%;
    margin:10px;
}

.aboutMeTopContainerPictureHolder{
    width:100%;
    height: 500px;
    /* background-color: red; */
}

.aboutMeTopContainerPictureHolder img{
    width:100%;
    height: 100%;
    object-fit: contain;
    /* background-color: red; */
}

.aboutMeBottomContainer{
    /* background-color: aqua; */
    max-width:100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin:10px;
}

.aboutMeBottomContainerPara{
    /* background-color: cadetblue; */
    max-width:50%;
    text-align: start;
}

@media  screen and (max-width: 800px) {
    .aboutMeSection{
        min-height: 400px;
    }
    
    .aboutMeTopContainerPictureHolder{
        width:100%;
        height: 300px;
        /* background-color: red; */
    }

    .aboutMeBottomContainer{
        justify-content: center;
    }

    .aboutMeBottomContainerPara{
        /* background-color: cadetblue; */
        max-width:95%;
        text-align: start;
    }
}

@media  screen and (max-width: 600px) {
    .heroSection{
        background-color: #FAFFFA;
        min-height: 300px;
        display: flex;
        justify-content: space-between;
    }
    
    .heroLeftContainer{
  
        max-width:50%;
        margin:10px;
    }

    .heroRightContainer{
        max-width:50%;
        margin:10px;
    }
}