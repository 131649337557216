.heroSection{
    background-color: #FAFFFA;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.heroLeftContainer{
    /* background-color: yellow; */
    max-width:100%;
    margin:10px;
}
.heroLeftContainerPara{
    /* background-color: pink; */
    max-width:50%;
    margin:10px;
}

.heroRightContainer{
    /* background-color: aqua; */
    max-width:100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin:10px;
}

.heroRightContainerPara{
    /* background-color: pink; */
    max-width:50%;
    margin:10px;
    text-align: left;
}

@media  screen and (max-width: 1000px) {
    .heroSection{
            background-color: #FAFFFA;
            min-height: 400px;
    }
    
    .heroLeftContainer{
        max-width:100%;
        margin:10px;
    }

    .heroLeftContainerPara{
        /* background-color: pink; */
        max-width:80%;
        margin:10px;
    }

    .heroRightContainerPara{
        /* background-color: pink; */
        max-width:50%;
        margin:10px;
        text-align: left;
    }
}

@media  screen and (max-width: 600px) {
    .heroSection{
        background-color: #FAFFFA;
        min-height: 300px;
        display: flex;
        justify-content: space-between;
    }


    .heroLeftContainerPara{
        /* background-color: pink; */
        max-width:80%;
        margin:10px;
    }

    .heroRightContainerPara{
        /* background-color: pink; */
        max-width:50%;
        margin:10px;
        text-align: left;
    }
}